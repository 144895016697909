import { combineReducers, Action } from "redux";
// import { AuthActionTypes } from "../types/authTypes";

// Add all reducers below to manage parts of the app state when combined
const appReducer = combineReducers({});

export const RootReducer = (state, action) => {
  //   if (action.type === AuthActionTypes.RESET_ROOT_STATE) {
  //     state = undefined;
  //   }
  return appReducer(state, action);
};
