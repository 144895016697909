import React, { useEffect, useState } from "react";
import "./start.scss";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "../../assets/images/dashboard.png";
import StartIcon from "../../assets/images/start.png";
import ArrowIcon from "../../assets/images/arrow.png";
import { get, post } from "../../singelton/axios";
import { Bounce, toast } from "react-toastify";

const Start = () => {
  const [startForm, setStartForm] = useState(false);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const showForm = () => {
    setStartForm(true);
  };
  const navigateToDashboard = () => {
    navigate("/dashboard");
  };
  useEffect(() => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setUserData(data);
  }, []);
  const [action, setAction] = useState("");
  const [dropdownList, setDropdownList] = useState({
    jobTypes: null,
    locations: null,
    locationsToShow: null,
  });
  const [location, setLocation] = useState("");

  useEffect(() => {
    if (!action) return;

    const recalculateLocation = async () => {
      let jobs = await get("/jobs");
      let invalidLocations = jobs?.map((x) => {
        if (x.job_status_types_id !== 4) {
          // not completed
          return x.locations_id;
        } else {
          return null;
        }
      });
      let list = structuredClone(dropdownList);
      list.locationsToShow = list.locations.filter((x) => {
        // show locations respective to job-type && locations that are not currently used by any job record
        // 1 location can have only 1 job

        return (
          x.job_types_id.toString() === action &&
          !invalidLocations?.includes(x.id)
        );
      });

      setDropdownList(list);
    };

    recalculateLocation();
  }, [action]);
  useEffect(() => {
    // show job types as actions for facility Id from userdata
    const getJobTypes = async () => {
      let userData = localStorage.getItem("userData");
      userData = JSON.parse(userData);
      let jobTypes = await get("/job_types");
      let locations = await get("/locations");
      locations = locations?.filter(
        (x) =>
          x.facility_id === userData.facility_id &&
          x.location_status_types_id !== 3 // should not be occupied
      );
      setDropdownList({
        jobTypes: jobTypes,
        locations: locations,
        locationsToShow: locations,
      });
    };

    getJobTypes();

    // take locations table and show locations that are not busy
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      Job_Start_Time: null,
      Job_Closed_Time: null,
      job_status_types_id: 1, //starting
      job_types_id: parseInt(action),
      locations_id: parseInt(location),
    };
    post("/jobs", body).then((res) => {
      toast.success("Job Created Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      let bodyAlerts = {
        alert_types_id: 3, // Start an unloading job
        Source_Type: "Customer",
        user_id: userData?.id,
        jobs_id: res.id,
        alerts_status_types_id: 1, // New
        dapster_id: 1,
      };
      post("/alerts", bodyAlerts).then((res) => {
        toast.success("Alert Customer Generated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        navigateToDashboard();
      });
      let loc = dropdownList?.locations?.find((x) => x.id === parseInt(location));
      if (loc?.location_status_types_id === 2) {
        // assuming Dock Door status as NOT SET UP
        let bodyAlerts = {
          alert_types_id: 4,
          Source_Type: "TeleOperator",
          user_id: userData?.id,
          jobs_id: res.id,
          alerts_status_types_id: 1, // New
          dapster_id: 1,
        };
        post("/alerts", bodyAlerts).then((res) => {
          toast.success("Alert TeleOperator Generated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          navigateToDashboard();
        });
      }
      setLocation("");
      setAction("");
    });
  };
  return (
    <div className="start-wrapper">
      {startForm ? (
        <div className="dapster-form">
          <div className="form-group">
            <label>What do you need Dapster to do?</label>
            <div className="select-wrapper">
              <select
                value={action}
                onChange={(e) => {
                  setAction(e.target.value);
                  setLocation("");
                }}
              >
                <option value="">Select an action</option>
                {dropdownList?.jobTypes?.map((item) => (
                  <option key={`jobs-${item.id}`} value={item.id}>
                    {item.Job_Type}
                  </option>
                ))}
              </select>
              <span className="alerts__select-arrow"></span>
            </div>
          </div>

          <div className="form-group">
            <label>Where do you need Dapster?</label>
            <div className="select-wrapper">
              <select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                <option value="">Select a location</option>
                {dropdownList?.locationsToShow?.map((item) => (
                  <option key={`locations-${item.id}`} value={item.id}>
                    {item?._location_types?.Location_types} -{" "}
                    {item?.Location_Number}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button onClick={handleSubmit} className="submit-button">
            SUBMIT
          </button>
        </div>
      ) : (
        <>
          <h1>
            Hello {userData?.NAME?.split(" ")[0]}! Select an option below to get
            started:
          </h1>
          <div className="start-flex-cards">
            <div className="start-card" onClick={showForm}>
              <img src={StartIcon} />
              <div className="start-card-heading">
                <h2>Let's Start</h2>
                <p>Get Dapster Working</p>
              </div>
              <div className="start-arrow-circle">
                <img src={ArrowIcon} />
              </div>
            </div>
            <div className="start-card" onClick={navigateToDashboard}>
              <img src={DashboardIcon} />
              <div className="start-card-heading">
                <h2>Your Dashboard</h2>
                <p>Alrts, Interventions & Jobs</p>
              </div>
              <div className="start-arrow-circle">
                <img src={ArrowIcon} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Start;
