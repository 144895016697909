import { setHeaders } from "../singelton/axios/index";
import authHeader from "../utils/auth-token-header";
import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {
  if (!localStorage.getItem("authUser")) {
    return <Navigate to={{ pathname: "/login" }} />;
  } else {
    const headerToken = authHeader();
    setHeaders(headerToken.Authorization);
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

export default Authmiddleware;
