// src/components/FormQuestion.js
import React from 'react';
import Button from '../../Button/Button';
import "./FormQuestion.css";

const FormQuestion = ({ questionText, showDropdown = false, dropdownOptions = [], onYes, onNo, onSelectOption }) => {
  return (
    <div className="form-question">
      <p className='question-text' >{questionText}</p>
      {showDropdown && dropdownOptions.length > 0 ? (
        <select onChange={(e) => onSelectOption(e.target.value)}>
          <option value="">Select an option</option>
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        <div className="button-group">
          <Button label={"Yes"} style={{width:"8%"}} onClick={onYes}/>
          <Button label={"No"} style={{width:"8%"}}  type="secondary" onClick={onYes}/>
        </div>
      )}
    </div>
  );
};

export default FormQuestion;
