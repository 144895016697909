// src/components/Dropdown/Dropdown.js
import React, { useState } from 'react';
import Button from '../Button/Button';
import './Dropdown.css';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap'; // Import Bootstrap's Dropdown

const DropdownComponent = ({ label, options,onSelect,style }) => {
    const [selectedLabel, setSelectedLabel] = useState(label); // Track the selected label

    const handleOptionClick = (option) => {
        setSelectedLabel(option.PauseClose_Rationales); // Update the label with the selected option
        console.log(`Selected: ${option}`);
        onSelect(option);
    };

    return (
        <div className="dropdown-container" >
            {/* Dropdown Menu (Using Bootstrap's Dropdown component) */}
            <BootstrapDropdown>
                <BootstrapDropdown.Toggle variant="success" id="dropdown-basic" style={style}>
                    {selectedLabel}
                </BootstrapDropdown.Toggle>

                <BootstrapDropdown.Menu>
                    {options.map((option, index) => (
                        <BootstrapDropdown.Item
                            key={index}
                            as="button" // Make it behave like a button
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.PauseClose_Rationales}
                        </BootstrapDropdown.Item>
                    ))}
                </BootstrapDropdown.Menu>
            </BootstrapDropdown>
        </div>
    );
};

export default DropdownComponent;
