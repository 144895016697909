import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/login/login";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import Layout from "./components/layout/layout";

function App() {
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<div>{route.component}</div>}
            key={idx}
            exact={true}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => {
          return (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout hideTabs={!!route?.hideTabs}>
                    {route.component}
                  </Layout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          );
        })}
        <Route exact path="/" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
