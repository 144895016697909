import React from "react";
import Dashboard from "../pages/dashboard/dashboard";
import Start from "../pages/start/start";
import Login from "../pages/login/login";
import WorkerPanel from "../pages/workers/workers";

const authProtectedRoutes = [
  // cctv-ai
  { path: "/start", component: <Start /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/reporting", component: <div>Reporting</div> },
  { path: "/cockpit", component: <div>Cockpit</div> },
  { path: "/worker", component: <WorkerPanel />, hideTabs: true },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <div>Forgot Password</div> },
  { path: "/pages-404", component: <div>Page 404</div> },
];

export { authProtectedRoutes, publicRoutes };
