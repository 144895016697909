import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/layout";
import CustomTable from "../../components/custom-table/custom-table";
import Tabs from "../../components/tabs/tabs";
import { subTabsForUsers } from "../../constants/config";
import { getUserType } from "../../utils/helper";
import { get } from "../../singelton/axios";
import Loader from "../../components/loader/loader";
import "./dashboard.scss"

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState();
  const [tablesData, setTablesData] = useState({});
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);

  const getTablesData = async (data) => {
    setLoader(true);
    const tablesData_ = {}; // Initialize an empty object to store the fetched data

    // Fetch jobs only if 'data' is "jobs" or 'null'
    let jobs;
    if (data === "jobs" || data == null) {
      jobs = await get("/jobs");
      tablesData_.jobs = sortArrayByKey(jobs, "id");
    } else {
      tablesData_.jobs = tablesData.jobs;
    }

    // Fetch alerts only if 'data' is "alerts" or 'null'
    let alerts, alertCustomer, alertDapster, alertTeleOp;
    if (
      data === "alerts" ||
      data === "client" ||
      data === "dapster" ||
      data == null
    ) {
      alerts = await get("/alerts");

      // Filter alerts for different conditions
      alertCustomer = alerts.filter(
        (x) => x.Source_Type === "Customer" && x.alerts_status_types_id === 1
      );
      alertDapster = alerts.filter(
        (x) => x.Source_Type === "Dapster" && x.alerts_status_types_id !== 3
      );
      alertTeleOp = alerts.filter(
        (x) =>
          x.Source_Type === "TeleOperator" &&
          [1, 2].includes(x.alerts_status_types_id)
      );

      tablesData_.totalAlerts = alerts;
      tablesData_.alerts = sortArrayByKey(alertTeleOp, "id");
      tablesData_.client = sortArrayByKey(alertCustomer, "id");
      tablesData_.dapster = sortArrayByKey(alertDapster, "id");
    } else {
      tablesData_.totalAlerts = tablesData.totalAlerts;
      tablesData_.alerts = tablesData.alerts;
      tablesData_.client = tablesData.client;
      tablesData_.dapster = tablesData.dapster;
    }

    // Fetch interventions only if 'data' is "interventions" or 'null'
    let interventions;
    if (data === "interventions" || data == null) {
      interventions = await get("/interventions");

      // Filter interventions
      interventions = interventions.filter(
        (x) => ![3, 6].includes(x.intervention_status_types_id)
      );

      tablesData_.interventions = sortArrayByKey(interventions, "id");
    } else {
      tablesData_.interventions = tablesData.interventions;
    }

    console.log("tables: ", tablesData_);

    // Update state with only the fetched data
    setTablesData(tablesData_);
    setLoader(false);
  };

  useEffect(() => {
    getTablesData(selectedTab?.toLowerCase() ?? null);
  }, [selectedTab]);

  const sortArrayByKey = (array, key, order = "asc") => {
    if (!Array.isArray(array) || !key) {
      throw new Error(
        "Invalid input: First parameter must be an array and key is required"
      );
    }

    return array.slice().sort((a, b) => {
      // Handle cases where the key doesn't exist in an object
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        throw new Error(`Key "${key}" not found in one or more objects`);
      }

      let valueA = a[key];
      let valueB = b[key];

      // Handle different types of values
      if (typeof valueA === "string") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      // Perform the comparison
      if (valueA < valueB) {
        return order === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  };
  const getData = () => {
    let tableConfig = {};
    if (selectedTab?.toLowerCase() === "alerts") {
      tableConfig.columns = [
        "Dock",
        "Created At",
        "Issue Detail",
        "Associate",
        "Assigned",
      ];
    } else if (selectedTab?.toLowerCase() === "interventions") {
      tableConfig.columns = [
        "Dock",
        "Created At",
        "Issue",
        "Worker",
        "Elapsed Time",
        "Status",
        "Re-Assign?",
      ];
    } else if (selectedTab?.toLowerCase() === "jobs") {
      tableConfig.columns = [
        "Dock",
        "Created At",
        "Note",
        "Rationales",
        "Status",
        "Action",
      ];
    } else if (selectedTab?.toLowerCase() === "client") {
      tableConfig.columns = [
        "Dock",
        "Created At",
        "Issuer Name",
        "Issue Detail",
        "Link",
        "Completed?",
      ];
    } else if (selectedTab?.toLowerCase() === "dapster") {
      tableConfig.columns = [
        "Issuer Name",
        "Created At",
        "Dock",
        "Issue Detail",
        "Link",
        "Resolved?",
        "Send Alert",
      ];
    }
    tableConfig.data = tablesData[selectedTab?.toLowerCase()];
    return tableConfig;
  };

  useEffect(() => {
    let userData_ = JSON.parse(localStorage.getItem("userData"));
    setUserData(userData_);

    setSelectedTab(subTabsForUsers[getUserType(userData_?.user_types_id)]?.[0]);
  }, []);

  return (
    <>
      <Tabs
        tabsArr={subTabsForUsers[getUserType(userData?.user_types_id)]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tablesData={tablesData}
      >
        {loader ? (
          <div className="loader-wrapper-dashboard">
            <Loader />
          </div>
        ) : (
          <CustomTable
            fetchAllData={getTablesData}
            data={getData()}
            selectedTab={selectedTab}
            tablesData={tablesData}
          />
        )}
        
      </Tabs>
    </>
  );
};

export default Dashboard;
