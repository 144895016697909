import React from "react";
import './Card.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = ({ 
    firstIcon, 
    heading, 
    description, 
    secondIcon, 
    iconStyle = {}, 
    arrowStyle = {},  
    onClick 
}) => {

    const handleOnClick=()=>{
        onClick();
    }

    
    return (
        <div className="start-card"  onClick={onClick}>
            {/* Dynamic Icon */}
            <FontAwesomeIcon icon={firstIcon} className="card-icon" style={{ ...iconStyle }} />

            {/* Card Heading */}
            <div className="start-card-heading">
                <h2>{heading}</h2>
                <p>{description}</p>
            </div>

            {/* Arrow Circle */}
            <div>
                <FontAwesomeIcon icon={secondIcon} style={{ ...arrowStyle }} />
            </div>
        </div>
    );
};

export default Card;
