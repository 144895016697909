import React from "react";
import "./tabs.scss";

const Tabs = ({
  tabsArr,
  selectedTab,
  setSelectedTab,
  tablesData,
  children,
}) => {
  const getCountForTab = (tab) => {
    return tablesData[tab.toLowerCase()]?.length
      ? ` ( ${tablesData[tab.toLowerCase()]?.length} )`
      : "";
  };
  return (
    <div className="tabs">
      <div className="tabs-wrap">
        {tabsArr?.map((tab) => {
          return (
            <div
              className={`tab ${
                tab?.toLowerCase() === selectedTab?.toLowerCase()
                  ? "selected-tab"
                  : ""
              }`}
              onClick={() => {
                setSelectedTab(tab);
              }}
            >
              {tab} <span>{getCountForTab(tab)}</span>
            </div>
          );
        })}
      </div>
      <div className="tabs-content">{children}</div>
    </div>
  );
};

export default Tabs;
