import React, { useEffect, useState } from "react";
import { get } from "../../singelton/axios";
import "../custom-table/custom-table.scss";

const Associate = ({ setAssociate, associate, users, currentRow }) => {
  return (
    <div>
      <td>
        <div className="alerts__select-wrapper">
          <select
            className="alerts__select"
            value={currentRow.id === associate.id ? associate.value : ""}
            onChange={(e) => {
              setAssociate({ value: e.target.value, id: currentRow.id });
            }}
          >
            <option value="">Select Associate</option>
            {users?.map((usr) => (
              <option value={usr.id}>{usr.NAME}</option>
            ))}
          </select>
          <span className="alerts__select-arrow"></span>
        </div>
      </td>
    </div>
  );
};

export default Associate;
