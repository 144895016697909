import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const Loader=()=>{
    return (
        <div className="loader-wrapper">
            <FontAwesomeIcon icon={faSpinner} spin />
        </div>
    )
}


export default Loader