import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { get, patch, post } from "../../singelton/axios";
import { Bounce, toast } from "react-toastify";

import "./rationale-modal.scss";
const RationaleModal = ({
  modalOpen,
  setModalOpen,
  selectedRecord,
  fetchAllData,
}) => {
  const [dapsterShould, setDapsterShould] = useState("");
  const [rationale, setRationale] = useState("");
  const [dropdownList, setDropdownList] = useState({
    dapsterShould: null,
    rationales: null,
  });
  const handleClose = () => {
    setModalOpen(false);
    setRationale("");
    setDapsterShould("");
  };
  useEffect(() => {
    // show job types as actions for facility Id from userdata
    const getDropdownData = async () => {
      let pauseOptions = await get("/pause_options");
      let pauseRationales = await get("/pause_close_rationales");
      setDropdownList({
        dapsterShould: pauseOptions,
        rationales: pauseRationales,
      });
    };
    getDropdownData();
  }, []);

  const handleSubmitRationale = () => {
    // if paused need rationale submit dapster action and rationales both
    let newStatus;
    if (selectedRecord.job_status_types_id === 5) {
      newStatus = 7;
    } else if (selectedRecord.job_status_types_id === 8) {
      newStatus = 9;
    }
    let jobBody = {
      ...selectedRecord,
      job_status_types_id: newStatus,
      pause_options_id: dapsterShould,
      pause_close_rationales_id: rationale,
    };

    const mapDapsterShouldToAlertType = {
      3: 11,
      4: 12,
      5: 13,
    };
    patch(`/jobs/${selectedRecord.id}`, jobBody)
      .then((res) => {
        setTimeout(() => {
          fetchAllData("jobs");
        }, 1000);
        toast.success("Job Status Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        if (dapsterShould && ![1, 2].includes(dapsterShould)) {
          let userdata = localStorage.getItem("userData");
          userdata = JSON.parse(userdata);
          let bodyAlerts = {
            alert_types_id: mapDapsterShouldToAlertType[dapsterShould], // dapsterShould
            Source_Type: "Dapster",
            user_id: userdata?.id,
            jobs_id: selectedRecord.id,
            alerts_status_types_id: 1, // New
            dapster_id: 1,
          };
          post("/alerts", bodyAlerts)
            .then((res) => {
              console.log("alerts dapster generated successfully");
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setModalOpen(false);
      });
    // if closed need rationale submit rationales only and null dapster action
  };

  const getSubmitButtonStatus = () => {
    let status =
      (selectedRecord.job_status_types_id === 5 &&
        dapsterShould &&
        rationale) ||
      (selectedRecord.job_status_types_id === 8 && rationale);
    return status;
  };
  return (
    <Modal show={modalOpen} onHide={handleClose} className="modal-dapster">
      <Modal.Header closeButton>
        <Modal.Title>
          You paused/stopped the following job. Tell Dapster what to do next:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="modal-dock">
            <div> Location: </div>
            <div className="modal-dock-value">
              {selectedRecord?._locations._location_types?.Location_types}:{" "}
              {selectedRecord?._locations.Location_Number}
            </div>
          </div>
          <div className="modal-form-group">
            <label>Dapster should</label>
            <div className="select-wrapper">
              <select
                disabled={selectedRecord?.job_status_types_id === 8}
                value={dapsterShould}
                onChange={(e) => setDapsterShould(e.target.value)}
              >
                <option value="">Select an action</option>
                {dropdownList?.dapsterShould?.map((item) => (
                  <option key={`dapster-${item.id}`} value={item.id}>
                    {item?.Pause_Options}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="modal-form-group">
            <label>Rationale</label>
            <div className="select-wrapper">
              <select
                value={rationale}
                onChange={(e) => setRationale(e.target.value)}
              >
                <option value="">Select an action</option>
                {dropdownList?.rationales?.map((item) => (
                  <option key={`rationales-${item.id}`} value={item.id}>
                    {item?.PauseClose_Rationales}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmitRationale}
          className={"rationale-button"}
          disabled={
            (selectedRecord?.job_status_types_id === 5 &&
              (!dapsterShould || !rationale)) ||
            (selectedRecord?.job_status_types_id === 8 && !rationale)
          }
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RationaleModal;
