// src/components/FormComponent.js
import React from 'react';
import FormQuestion from './FormQuestion/FormQuestion';
import './Form.css'
import Button from '../Button/Button';
import { icon } from '@fortawesome/fontawesome-svg-core';

const FormComponent = ({ questions }) => {
  const handleYes = (questionIndex) => {
    console.log(`Yes clicked for question ${questionIndex}`);
  };

  const handleNo = (questionIndex) => {
    console.log(`No clicked for question ${questionIndex}`);
  };

  const handleSelectOption = (questionIndex, value) => {
    console.log(`Option selected for question ${questionIndex}: ${value}`);
  };

  return (
    <div className="form-container">
        <div style={{fontWeight:"500", fontSize:"18px",height:"50px"}}>1. confirm the issue dapster is reporting the issue</div>
        <div style={{fontWeight:"500",height:"40px",display:"flex",alignItems:"center"}}>Unpickable box(es)</div>
      {questions.map((question, index) => (
        <div className='questions'><FormQuestion 
          key={index}
          questionText={question.text}
          showDropdown={question.showDropdown}
          dropdownOptions={question.dropdownOptions || []}
          onYes={() => handleYes(index)}
          onNo={() => handleNo(index)}
          onSelectOption={(value) => handleSelectOption(index, value)}
        /></div>
      ))}
      <Button label={"Submit"} style={{
        width:"100%",
        height:"50px",
        textAlign:"center",
        
      }}icon={null}/>
    </div>
  );
};

export default FormComponent;
