import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import accessToken from "../../utils/access-token";
import { post } from "../../singelton/axios";
import { userTypesId } from "../../constants/config";
import { faE, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const loginHandler = () => {
    let body = {
      EMAIL: email,
      PASSWORD: password,
    };
    post("auth/login", body)
      .then((res) => {
        if (res?.authToken) {
          let accessToken_ = JSON.stringify(accessToken);
          localStorage.setItem("authUser", accessToken_);
          localStorage.setItem("userData", JSON.stringify(res.user));
          // we will decide here to route to particular route based on roles after login
          if (res?.user?.user_types_id === userTypesId["Teleoperator"]) {
            navigate("/dashboard");
          } else if (res?.user?.user_types_id === userTypesId["Supervisor"]) {
            navigate("/start");
          } else if (res?.user?.user_types_id === userTypesId["Worker"]) {
            navigate("/worker");
          } else {
            navigate("/dashboard");
          }
        }
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        console.log("console:", err);
      });
  };
  return (
    <div className="login-page">
      <div className="login-app-name">Dapster</div>
      <div className="login-container">
        <h1 className="login-title">Welcome To Dapster</h1>
        <div className="login-form">
          <label className="login-label">Username</label>
          <input
            type="text"
            className="login-input"
            placeholder="Enter your username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className="login-label">Password</label>
          <div className="password-input-container">
            <input
              type={showPass ? "text" : "password"}
              className="login-input"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className="password-toggle-btn"
              onClick={() => setShowPass(t=>!t)}
            >
              <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} />
            </button>
          </div>
          <a href="#" className="forgot-password">
            Forgot Password?
          </a>
          <button className="login-btn" onClick={loginHandler}>
            LOG IN
          </button>
          {message && <div className="invalid-message">{message}</div>}
        </div>
      </div>
    </div>
  );
};

export default Login;
