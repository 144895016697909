import React, { useEffect, useState } from "react";
import "./layout.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { tabsForUsers } from "../../constants/config";
import { getUserType } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = ({ hideTabs, children }) => {
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const logout = () => {
    navigate("/login");
    localStorage.removeItem("authUser");
    localStorage.removeItem("userData");
  };
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    //based on users
    setTabs(tabsForUsers[getUserType(userData?.user_types_id)]);
  }, []);
  useEffect(() => {
    if (tabs?.length > 0) setSelectedTab(location.pathname.replace("/", ""));
  }, [location, tabs]);
  return (
    <div className="layout">
      <div className="layout-header">Dapster</div>
      <div className="layout-content">
        {!hideTabs && (
          <div className="layout-tabs-wrap">
            {tabs?.map((tab) => {
              return (
                <div
                  className={`layout-tab ${
                    tab?.toLowerCase() === selectedTab?.toLowerCase()
                      ? "selected-tab"
                      : ""
                  }`}
                  onClick={() => {
                    navigate(`/${tab}`);
                  }}
                >
                  {tab}
                </div>
              );
            })}
          </div>
        )}
        <div className="layout-children">
          {children}
          <button onClick={() => logout()}>Logout</button>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  );
};

export default Layout;
