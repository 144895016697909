export const tabsForUsers = {
  Teleoperator: ["Dashboard", "Cockpit"],
  Supervisor: ["Start", "Dashboard", "Reporting"],
  Worker: [],
};

export const subTabsForUsers = {
  Supervisor: ["Alerts", "Interventions", "Jobs"],
  Teleoperator: ["Client", "Dapster", "Interventions", "Jobs"],
};

export const userTypesId = {
  Supervisor: 1,
  Worker: 2,
  Teleoperator: 3,
};

export const columnToKeyMap = {
  Alerts: {
    Dock: "_jobs",
    "Created At": "created_at",
    "Issue Detail": "_alert_types",
  },
  Interventions: {
    Dock: "_locations",
    "Created At": "created_at",
    Issue: "_intervention_types",
    Worker: "_user",
    "Elapsed Time": "",
    Status: "_intervention_status_types",
  },
  Jobs: {
    Dock: "_locations",
    "Created At": "created_at",
    Note: "_job_types",
    Status: "_job_status_types",
    Rationales: "_pause_close_rationales",
  },
  Client: {
    Dock: "_jobs",
    "Created At": "created_at",
    "Issue Detail": "_alert_types",
    "Issuer Name": "_user",
    Link: "",
  },
  Dapster: {
    "Issuer Name": "_user",
    Dock: "_jobs",
    "Created At": "created_at",
    "Issue Detail": "_alert_types",
    Link: "",
  },
};
