import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { subTabsForUsers } from "../../constants/config";
import { getUserType } from "../../utils/helper";
import { patch, post, get } from "../../singelton/axios";
import FormComponent from "../../components/Form/Form";
import { Bounce, toast } from "react-toastify";
import {
  faCirclePause,
  faCircleStop,
  faHourglassHalf,
  faQuestion,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import "./worker.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "../../components/card/Card";
import DropdownComponent from "../../components/Dropdown/Dropdown";
import { updateLocale } from "moment/moment";

const WorkerPanel = () => {
  const [screen, setScreen] = useState(null);
  const [userData, setUserData] = useState(null);
  const [options, setOptions] = useState([]);
  const [job, setJob] = useState(null);
  const [isHilighted, setHighlighted] = useState(false);
  const [isDisableButton, setDisableButton] = useState(false);
  const [selectedPauseOption, setPauseOption] = useState(null);
  const [isUpdated, setIsUpdated] = useState(true); // State to track if data is updated
  const [intervention,setIntervention]=useState(null);

  // dropdown selected handler for worker screen 1
  const onSelect = (option) => {
    console.log("i am in worker", option);
    setPauseOption(option);
  }

  //getting the pause options from table Pause Options
  const getPauseOptions = async () => {
    const pause_options = await get("/pause_close_rationales");
    return pause_options;

  }



  // stop button handle changes the job status type to close need rational or pause Need Rational
  const pauseOrCloseJob = async (status) => {
    if (!selectedPauseOption) {
      setHighlighted(true);
      toast.warn("Alert! Job Paused/Cancel Rationales required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      })
    }
    else {
      const UpdatedJob = {
        ...job,
        job_status_types_id: status, //Closed-Need-Rationale
        pause_close_rationales_id:selectedPauseOption.id
      }
      patch(`/jobs/${job.id}`, UpdatedJob)
        .then((res) => {
          console.log(res);
          setIsUpdated(true);
        })
        .catch(error=>{
          toast.error(`${error.message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          })
        })

    }

  }




  // change the intervention status to Arrived
  const handleCardClick=()=>{
      if(intervention)
      {
        const interventionBody={
          ...intervention,
          intervention_status_types_id:4 //Arrived
        }
        patch(`/interventions/${intervention.id}`, interventionBody)
        .then((res) => {
          console.log(res);
          setIsUpdated(true);
        })
        .catch(error=>{
          toast.error(`${error.message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          })
        })

      }
  }



  //questions for 4th screen
  const questions = [
    {
      text: "Does this issue/problem listed above appear to be correct?",
      showDropdown: false,
    },
    {
      text: "Can you fix it?",
      showDropdown: false,
    },
    {
      text: "Do you need to watch a training video first?",
      showDropdown: false,
    },
  ];
  // Todo:
  // Take location for userdata from localstorage
  // take jobs list
  // show active-component if job with starting status exist on this location
  // show pause-component if job with paused status exist on this location
  // try to use switch based on job status to render respective component

  useEffect(() => {
    const fetchJobs = async () => {
      let userdata = localStorage.getItem("userData");
      userdata = JSON.parse(userdata);
      setUserData(userdata);
      const jobs = await get("/jobs");
      const cur_active_job = jobs.filter(
        (job) =>
          job.locations_id === userdata.locations_id &&
          job.job_status_types_id === 1
      ); // starting
      setJob(cur_active_job[0]);
      if (cur_active_job.length) {
        setScreen(1);
        const pause_options = await getPauseOptions();
        setOptions(pause_options);
        console.log(pause_options);
        return;
      }
      const cur_paused_job = jobs.filter(
        (job) =>
          job.locations_id === userdata.locations_id &&
          job.job_status_types_id === 7
      ); // paused
      if (cur_paused_job.length) {
        const interventions = await getInterventions();
        const filteredInterventions = interventions.filter(
          (intervention) =>
            intervention.locations_id === userdata.locations_id &&
            (intervention.intervention_status_types_id === 2 ||
              intervention.intervention_status_types_id === 4)
        ); //Assigned=#2  Arrived=#4
        if (!filteredInterventions.length) setScreen(2);
        else {
          const AssignedInterventionStatus = filteredInterventions.filter(
            (intervention) => intervention.intervention_status_types_id === 2
          ); //Assigned
          if (AssignedInterventionStatus.length){ 
            setIntervention(AssignedInterventionStatus[0]);
            setScreen(3)
            
          };
          const ArrivedInterventionStatus = filteredInterventions.filter(
            (intervention) => intervention.intervention_status_types_id === 4
          ); //Arrived
          if (ArrivedInterventionStatus.length) setScreen(4);
        }
      }
    };
    if (isUpdated) {
      fetchJobs();
      setIsUpdated(false);
    }
  }, [isUpdated]);

  const getInterventions = async () => {
    const intervention = await get("/interventions");
    return intervention;
  };

  return (
    <>
      {screen == 1 ? (
        <div>
          {" "}
          <h2>Dapster is hard at work.</h2>
          <p style={{ color: "#A90505", fontWeight: "bold", fontSize: "30px" }}>
            Please do not enter the container
          </p>
          <p>
            If you need to enter or pause Dapster, please submit your request.
          </p>
          <p>
            A Supervisor will approve the request.Do not enter the container
            until this screen says it is safe to do so
          </p>
          <div className="align-buttons">
            <Button
              onClick={() => pauseOrCloseJob(9)}
              label="Stop (it's an emergency)"
              type="primary"
              icon={faCircleStop}
            />
            <Button
              onClick={() => pauseOrCloseJob(7)}
              label="Pause/Cancel"
              type="secondary"
              icon={faCirclePause}
            />
            <DropdownComponent
              label="Why do you want to Cancel?"
              type="secondary"
              options={options}
              onSelect={onSelect}
              style={isHilighted ? { border: "1px solid red" } : null}
            />

          </div>
        </div>
      ) : null}
      {screen == 2 ? (
        <div>
          <h2 style={{ paddingTop: "50px" }}>
            Dapster is Pausing, and your request has been sent to a Supervisor
          </h2>
          <FontAwesomeIcon
            icon={faHourglassHalf}
            size="5x"
            style={{ color: "#09375F" }}
          />
          <p style={{ color: "#A90505", fontWeight: "bold", fontSize: "30px" }}>
            Please do not enter the container
          </p>
          <p style={{ fontWeight: "500" }}>
            We will let You know when it is safe to enter the container
          </p>
        </div>
      ) : null}
      {screen === 3 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <Card
            heading={"I am here to help"}
            firstIcon={faQuestion}
            secondIcon={faSquareArrowUpRight}
            iconStyle={{
              color: "#fafafa",
              backgroundColor: "#09375f",
              padding: "5px",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
            arrowStyle={{
              color: "#09375f",
              fontSize: "50px",
            }}
            description={"Lorem Ipsum is simply dummy text"}
            onClick={handleCardClick}
          />
          <Card
            heading={"¡Estoy aqui para ayudar!"}
            firstIcon={faQuestion}
            secondIcon={faSquareArrowUpRight}
            iconStyle={{
              color: "#fafafa",
              backgroundColor: "#09375f",
              padding: "5px",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
            arrowStyle={{
              color: "#09375f",
              fontSize: "50px",
            }}
            description={"Lorem Ipsum is simply dummy text"}
            onClick={handleCardClick}
          />
        </div>
      ) : null}
      {screen === 4 ? (
        <div style={{ width: "100%", marinTop: "-5px" }}>
          <h1>Thank you for your help {userData.NAME}!</h1>
          <FormComponent questions={questions} />
        </div>
      ) : null}
    </>
  );
};

export default WorkerPanel;
