// src/components/Button/Button.js
import React from 'react';
import './Button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = ({ label, style, onClick, type = "primary", icon, iconSize, iconColor, disabled = false }) => {

  return (
    <button
      className={`button ${type} ${disabled ? 'disabled' : ''}`} // Add 'disabled' class when the button is disabled
      style={{ ...style, opacity: disabled ? 0.5 : 1 }} // Reduce visibility if disabled
      onClick={disabled ? null : onClick} // Disable the onClick handler when disabled
      disabled={disabled} // Set the native disabled attribute to true
    >
      {icon && <FontAwesomeIcon icon={icon} size={iconSize || 'lg'} style={{ color: iconColor, size: iconSize }} />} {/* Conditionally render the icon */}
      <span>{label}</span>
    </button>
  );
};

export default Button;
