import { userTypesId } from "../constants/config";
import moment from "moment";
export const getUserType = (index) => {
  return Object.keys(userTypesId)[index - 1];
};

export const epochToReadableDate = (epochTimestamp) => {
  // Create a new Date object using the epoch timestamp (in milliseconds)
  //   const date = new Date(epochTimestamp * 1000);

  // Format the date using toLocaleString() for a readable format
  return moment(epochTimestamp).format("DD-MMM-YYYY h:mm a");
};
